import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import PaginationUtil from "../../helpers/PaginationUtil";
import PostCategory from "../../elements/breadcrumb/PostCategory";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";


const ReviewBlog = () => {

    let count = 1;


    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        let _pageNumber =  event.selected;
        setSearchParams(prevState => {
            return {
                ...prevState,
                pageNumber: _pageNumber
            }
        })
        setSearchOnChange(true);
        console.log(
            `User requested page number ${event.selected}`
        );
    };

    const [searchOnChange, setSearchOnChange] = useState(false);
    const [searchParams, setSearchParams] = useState({
        pageSize : 10
        , pageNumber : 0
    });

    const [listInfo, setListInfo] = useState({
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        handleSearch();
    }, [])

    useEffect(() => {
        if(searchOnChange) {
            handleSearch();
            setSearchOnChange(false);
        }
    }, [searchOnChange])

    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogList(searchParams)
            .then(res => {
                    setListInfo(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    });
                    count = res.data.totalElements;
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <>
            <SEO title="KONA CHAIN | Review Blog"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="Review Blog"
                />
                <div className="container mb--100 mt--20">
                        <div className="row">

                            <span>Total: <span style={{color: "red", fontWeight: "bold"}}>{listInfo.totalElements}</span></span>
                            <table className="table table-bordered table-hover">
                                <thead>
                                <tr className={"table-info align-middle"} align={"center"}>
                                    <th scope="col" style={{width: "5%"}}>SL</th>
                                    <th scope="col" style={{width: "10%"}}>Category</th>
                                    <th scope="col" style={{width: "35%"}}>Title</th>
                                    <th scope="col" style={{width: "15%"}}>Request Date</th>
                                    <th scope="col" style={{width: "15%"}}>Approve Date</th>
                                    <th scope="col" style={{width: "10%"}}>Status</th>
                                    <th scope="col" style={{width: "10%"}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>

                                {listInfo.content.map((blog) => (

                                    <tr>
                                        <td>{count++}</td>
                                        <td> <PostCategory categoryId={blog.categoryId}/> </td>
                                        <td>
                                            <Link className={"kc-text-color-black"}
                                                  to={process.env.PUBLIC_URL + "/review-blog-details/" + blog.id} dangerouslySetInnerHTML={{__html: blog.title}}>
                                            </Link>
                                        </td>

                                        <td align={"center"}>{DateUtil.format(blog.postDate)}</td>
                                        <td align={"center"}>{DateUtil.format(blog.publishedDate)}</td>
                                        <td>
                                            {blog.status === 'PENDING' &&
                                            <span className="badge bg-warning text-dark">{blog.status}</span>
                                            }
                                            {blog.status === 'REJECT' &&
                                            <span className="badge bg-danger">{blog.status}</span>
                                            }
                                            {blog.status === 'APPROVED' &&
                                            <span className="badge bg-success">{blog.status}</span>
                                            }
                                        </td>
                                        <td>
                                            <Link to={process.env.PUBLIC_URL + "/review-blog-details/"+ blog.id}>
                                                <button type="button" className="btn btn-outline-info btn-sm">Details</button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>

                            <PaginationUtil
                                handlePageClick={handlePageClick}
                                totalPages={listInfo.totalPages}
                            />
                        </div>
                    </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default ReviewBlog;
