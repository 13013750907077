import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {

    const form = useRef();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        // emailjs.sendForm('service_yj5dgzp', 'template_hfduayo', form.current, 'WLENsTkBytC0yvItS')
        //     .then((result) => {
        //         console.log(result.text);
        //     }, (error) => {
        //         console.log(error.text);
        //     });

        //TODO: Collect form data and send it to the server.

        form.current.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>회사명</label>
                <input type="text" className="form-control" name="companyName" required/>
            </div>
            <div className="form-group">
                <label>부서/직급</label>
                <input type="text" className="form-control" name="department" required/>
            </div>
            <div className="form-group">
                <label>성명</label>
                <input type="text" className="form-control" name="department" required/>
            </div>
            <div className="form-group">
                <label>회사연락처</label>
                <input type="tel" className="form-control" name="companyContact" required/>
            </div>
            <div className="form-group">
                <label>회사 E-mail</label>
                <input type="email" className="form-control" name="companyEmail" required/>
            </div>
            <div className="form-group">
                <label>첨부파일</label>
                <input type="file" className="form-control" name="file"/>
                <p style={{fontSize : "12px", margin : "5px 0 0 5px"}}>설문지, 조사계획서 등을 첨부해주시면 더욱 정확한 안내를 드리겠습니다.<br/>
                    파일 확장자는 .doc, .docx, .hwp 형식이 가능하며 최대 50 MB 가능합니다.</p>
            </div>

            <div className="form-group mb--40">
                <label>문의내용</label>
                <textarea className="form-control" name="contact-message" rows="4"></textarea>
            </div>
            <div className="form-group mb--40">
                <div style={{width : "20px", float: "left", height: "20px", marginRight: "10px"}}>
                    <input type="checkbox" name="contact-message" style={{height: "20px"}}></input>
                </div>
                <div>동의합니다</div>
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">문의하기
                </button>
            </div>
            <div className="form-group">
                {result ? <Result/> : null}
            </div>

        </form>
    )
}

export default FormTwo;
