import {api} from "./";
import {paramUtil, storageUtil} from "../helpers";

export const blogApi = {
    postBlog
    , updatePostBlog
    , updateBlog
    , getBlogList
    , getBlogDetails
    , getMyBlogList
}

const authToken = storageUtil.getAuthToken();

function postBlog(requestPayload) {
    console.log("authToken");
    console.log(authToken);
    return api.post('/api/post/create', paramUtil.removeBlankAttribute(requestPayload))
}

function updatePostBlog(requestPayload) {
    return api.post('/api/post/admin/update', paramUtil.removeBlankAttribute(requestPayload))
}

function updateBlog(requestPayload) {
    return api.post('/api/post/admin/update-status', paramUtil.removeBlankAttribute(requestPayload))
}

function getBlogList(requestPayload) {
    return api.get('/api/post/admin/get-list', paramUtil.stringifyRequestPayload(requestPayload))
}

function getBlogDetails(requestPayload) {
    return api.get('/api/post/get/details', paramUtil.stringifyRequestPayload(requestPayload))
}

function getMyBlogList(requestPayload) {
    return api.get('/api/post/get-my-list', paramUtil.stringifyRequestPayload(requestPayload))
}

