/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 10/19/2022
 */
import {api} from "./";
import {paramUtil} from "../helpers";

export const inquiryApi = {
    getInquiryList
    , getInquiryDetails
}

function getInquiryList(requestPayload) {
    return api.get('/api/contact/get-list', paramUtil.stringifyRequestPayload(requestPayload))
}

function getInquiryDetails(inquiryId) {
    return api.get('/api/contact/get/details?contactId='+inquiryId)
}