/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 10/19/2022
 */


export const DEFAULT_CONSTANTS = {
    MAX_IMAGE_UPLOAD_SIZE: 5242880,
}

export const UPLOAD_FILE_TYPE_EXTENSION_LIST = {
    TXT: "text/plain",
    CSV: ".csv",
    JPG: "image/vnd.sealedmedia.softseal.jpg",
    JPEG: "image/jpeg",
    PNG: "image/png",
    GIF: "image/gif",
    BMP: "image/bmp",
    PDF: "application/pdf",
    DOC: "application/msword",
    XLS: "application/vnd.ms-excel",
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    PPT: "application/vnd.ms-powerpoint",
    PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    HTML: "text/html",
    HTM: "text/htm",
    MDB: ".mdb",
    HWP: ".hwp",
    SVG: "image/svg+xml",
    ALL_IMAGE: "image/*"
};

export const Constants = {
    APP_SESSION_KEY: {
        USER_NAME: "USER_NAME"
        , TOKEN: "TOKEN"
        , REFRESH_TOKEN: "REFRESH_TOKEN"
        , TOKEN_EXPIRE: "TOKEN_EXPIRE"
        , REFRESH_TOKEN_EXPIRE: "REFRESH_TOKEN_EXPIRE"
        , LOGIN_FULL_RESPONSE: "LOGIN_FULL_RESPONSE"
    },
    UPLOAD_PRODUCT_IMAGE_ALLOWED_TYPE: [
        UPLOAD_FILE_TYPE_EXTENSION_LIST.JPG,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.PNG,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.JPEG
    ],
    UPLOAD_PRODUCT_FILE_ALLOWED_TYPE: [
        UPLOAD_FILE_TYPE_EXTENSION_LIST.XLS,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.XLSX,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.DOC,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.DOCX,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.PDF,
        UPLOAD_FILE_TYPE_EXTENSION_LIST.HWP,
    ],
    MAX_IMAGE_UPLOAD_SIZE: 5242880,
}

