/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 10/19/2022
 */
import {api} from "./";

export const LoginApi = {
    login
}

function login(payload) {
    return api.post('/api/auth/login', payload)
}