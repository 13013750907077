import React, {useEffect, useState} from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import {Link, useParams} from "react-router-dom";
import {FaEnvelope, FaFileAlt, FaFilePdf, FaFileWord, FaMobileAlt} from "react-icons/fa";
import {inquiryApi} from "../../api/inquiryApi";
import {CommonUtil} from "../../helpers";
import {DateUtil} from "../../helpers/DateUtil";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";

export const InquiryDetailsDefault = {
    id: 0,
    companyName: "",
    department: "",
    name: "",
    companyPhoneNumber: "",
    companyEmail: "",
    contactMessage: "",
    hasAttachment: false,
    createTime: "",
    updateTime: "",
    status: "",
    attachmentList: []
}

const CustomerContactDetails = () => {
    const params = useParams();
    const requestInquiryId = parseInt(params.id);
    console.log(inquiryId);

    const [inquiryId, setInquiryId] = useState(undefined);
    const [inquiryDetails, setInquiryDetails] = useState(InquiryDetailsDefault);

    useEffect(() => {
        if(requestInquiryId && requestInquiryId !== inquiryId) {
            setInquiryId(requestInquiryId);
        }
    }, [requestInquiryId])

    useEffect(() => {
        if(inquiryId) {
            inquiryApi.getInquiryDetails(inquiryId)
                .then(res => {
                        setInquiryDetails(prevState => {
                            return {
                                ...prevState,
                                ...res.data
                            }
                        })
                        setAttachmentListData(res.data.attachmentList)
                    }
                )
                .catch(err => CommonUtil.handleErrorResponse(err));

        }
    }, [inquiryId]);

    const [attachmentList, setAttachmentList] = useState([]);
    const setAttachmentListData = (attachmentList) => {
        let currentAttachmentList = [];
        attachmentList.map((attachment) => {
            currentAttachmentList.push(attachment.fileUrl);
        })
        setAttachmentList(currentAttachmentList);
    }

    return (
        <>
            <SEO title="KONA CHAIN | 견적문의" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/inquiry-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText = "Request Inquiry Details"
                />

                <div className="section mt--20 mb--100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="team-details-content">
                                    <h5 className="title">{inquiryDetails.companyName}</h5>
                                    <span className="subtitle">{inquiryDetails.name}</span>
                                    <ul className="social-share list-unstyled">
                                        <li>{DateUtil.format(inquiryDetails.createTime)}</li>
                                    </ul>
                                    <p>{inquiryDetails.contactMessage}</p>

                                    <div className="testimonial-grid "><span className="social-media">{inquiryDetails.companyName}</span>
                                        <div className="author-info">
                                            <div className="content" style={{marginLeft:"0px"}}>
                                                <span className="name">{inquiryDetails.name}</span>
                                                <span className="designation">{inquiryDetails.department}</span>
                                                <span className="info-msg"><FaEnvelope/> <a href="mailto:admin@konachain.co.kr">{inquiryDetails.companyEmail}</a></span>
                                                <span className="phone"><FaMobileAlt/> <a href="tel:+88888">{inquiryDetails.companyPhoneNumber}</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    {inquiryDetails.hasAttachment &&
                                        <>
                                            <h6>Attachment</h6>
                                            <AttachmentListGenerate
                                                attachmentObject={attachmentList}
                                            />
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default CustomerContactDetails;
