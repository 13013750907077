import React, {useEffect, useState} from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import {Link, useParams} from "react-router-dom";
import {FaFileWord} from "react-icons/fa";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";


const KonaChainNewsDetails = () => {
    const params = useParams();
    const inquiryId = parseInt(params.id);

    const [searchParams, setSearchParams] = useState({
        postId:inquiryId
    });

    useEffect(() => {
        handleSearch();
    }, [inquiryId])

    const [blogDetails, setBlogDetails] = useState({
        id: "",
        title: "",
        titleImage: "",
        description: "",
        postDate: "",
        authorId: "",
        reviewerId: null,
        reviewerNote: null,
        publishedDate: null,
        status: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        authorInfo: null,
        reviewerInfo: null,
        category: null,
        tagList: [],
        hasAttachment:false,
        attachmentList: []
    });

    const handleSearch = () => {
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                    setBlogDetails(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <>
            <SEO title="KONA CHAIN | 코나체인 소식" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                title="코나체인 소식"
                page="코나체인 소식"
                />

                <div className="section mt--80">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 mb--100">
                                <div className="team-details-content">
                                    <h5 className="title" style={{borderBottom: "2px solid var(--color-primary)", margin: "0 0 10px 0"}}
                                        dangerouslySetInnerHTML={{__html: blogDetails.title}}>
                                    </h5>

                                    <ul className="social-share list-unstyled">
                                        <li>작성일 : {DateUtil.format(blogDetails.postDate)}</li>
                                    </ul>
                                    <p dangerouslySetInnerHTML={{__html: blogDetails.description}}></p>

                                    {blogDetails.hasAttachment &&
                                       <>
                                           <h6>Attachment</h6>
                                           <div className="row">
                                               <div className="col-md-4 col-sm-6 mb--10">
                                                   <ol className="list-style">
                                                    <AttachmentListGenerate
                                                        attachmentObject={blogDetails.attachmentList}/>
                                                   </ol>
                                               </div>
                                           </div>
                                       </>
                                    }


                                    <div className="mt--20">
                                        <Link to={process.env.PUBLIC_URL + "/kona-chain-news"}>
                                            <button type="button" className="btn btn-dark">List</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default KonaChainNewsDetails;
