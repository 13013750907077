import React from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import ContactHeader from "../../component/contact/ContactHeader";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";



const CustomerContact = () => {
    return (
        <>
            <SEO title="KONA CHAIN | 견적문의" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />

                <BannerHomePageThree
                    backgroundImageUrl = "/images/banner/inquiry-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText = "Request Inquiry"
                />

                <ContactHeader/>


            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default CustomerContact;
