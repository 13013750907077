import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import BcrumbBannerOne from '../../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../../component/cta/CtaLayoutOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../../component/casestudy/CaseStudyProp';
import {Link} from "react-router-dom";
import BreadCrumbOne from "../../elements/breadcrumb/BreadCrumbOne";
import DocumentViewCss from "../../assets/css/document-view-main.css"
import SplashFooter from "../../common/footer/SplashFooter";


const VotingServiceDocuments = () => {

    return (
        <>
        <SEO title="KONA CHAIN | 설문조사&투표 서비스" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                {/*<BreadCrumbOne*/}
                {/*    title=""*/}
                {/*    page="설문조사&투표 서비스"*/}

                {/*/>*/}
                <div className="pt--150 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <div className="main-content-wrapper">
                            <div className="container-fluid">
                                <div className="main-content p-t-40">
                                    <aside className="sidebar-navigation">
                                        <nav className="doc-nav-main">

                                            {/*<form action="#" className="search-form m-b-20">*/}
                                            {/*    <div className="input-group">*/}
                                            {/*        <input type="text" className="search-input"*/}
                                            {/*               placeholder="Search item"/>*/}
                                            {/*    </div>*/}
                                            {/*</form>*/}
                                            <ol className="doc-nav">
                                                <li>
                                                    <a className="doc-nav-link" href="#section-1">Introduction</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-1-1">Requirements</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-1-2">What's
                                                            Included</a></li>
                                                    </ol>
                                                </li>
                                                <li><a className="doc-nav-link" href="#section-2">React Installation</a>
                                                </li>
                                                <li>
                                                    <a className="doc-nav-link" href="#section-3-1">Basic Site Settings</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-3-1">Change Site
                                                            Title and Favicon</a></li>
                                                        <li><a className="doc-nav-link" href="#section-3-2">Change Logo</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-3-3">Customize
                                                            Menu</a></li>
                                                        <li><a className="doc-nav-link" href="#section-3-4">Default Home
                                                            Setup</a></li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    <a className="doc-nav-link" href="#section-4-1">Template Options</a>
                                                    <ol className="sub-nav">
                                                        <li><a className="doc-nav-link" href="#section-4-1">Header</a></li>
                                                        <li><a className="doc-nav-link" href="#section-4-2">Footer</a></li>
                                                        <li><a className="doc-nav-link" href="#section-4-3">Social Icon</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-4-4">Page Banner</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-4-5">Coming Soon</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-4-6">Global Color</a>
                                                        </li>
                                                        <li><a className="doc-nav-link" href="#section-4-7">RTL Version</a>
                                                        </li>
                                                    </ol>
                                                </li>

                                                <li><a className="doc-nav-link" href="#section-5">Support</a></li>
                                            </ol>
                                        </nav>
                                    </aside>
                                    <main className="data_main">
                                        <div className="filterable-wrapper">
                                            <section id="section-1">
                                                <h3 className="mt-0">1. Introduction</h3>
                                                <p>We would like to thank you for choosing Abstrak. </p>
                                                <p>We made Abstrak from the ground-up with flexibility in mind. Each
                                                    element of Abstrak
                                                    is extremely customizable, where you can make \n Abstrak to
                                                        reflect your own
                                                        branding styles.</p>
                                                <p> The guide gives you detailed methodologies about how you can
                                                    customize Abstrak and
                                                    make it fit your brand perfectly! </p>
                                            </section>

                                            <section id="section-1-1">
                                                <h3>1.1 Requirements</h3>
                                                <p>There are system requirements in order to install and setup Abstrak
                                                    template and its components properly. Make sure that you are running
                                                    the <strong>node.js</strong> and <strong>npm</strong> in your
                                                    computer. You have to maintain this version <strong>(Node js >=
                                                        14.0.0 and npm >= 5.6)</strong></p>

                                                <p>If you don't have Node js in your computer please download and
                                                    install from here: <a href="https://nodejs.org/en/download/">https://nodejs.org/en/download/</a>
                                                </p>

                                                <img src="assets/images/doc/node.jpg" alt="Node Js Download"/>

                                                    <h5 className="m-b-10">Install Npm Packages</h5>
                                                    <p><strong>NPM :</strong> You must have to <strong>npm
                                                        install</strong> packages. You can do this by running npm
                                                        install from the <strong>root of your project</strong> to
                                                        install all the necessary dependencies.</p>
                                                    <pre>
								<code>
									npm install
								</code>
							</pre>

                                                    <h5 className="m-b-10">Development server</h5>
                                                    <p>Run npm run start for a dev server. Navigate to
                                                        http://localhost:3000/ . The app will automatically reload if
                                                        you change any of the source files.</p>
                                                    <pre>
								<code>
									npm run start
								</code>
							</pre>

                                                    <h5 className="m-b-10">Build</h5>
                                                    <p>Run npm run build to build the project. The build artifacts will
                                                        be stored in the build/ directory. Use the npm run build --prod
                                                        flag for a production build.</p>
                                                    <pre>
								<code>
									npm run build
									npm run build --prod
								</code>
							</pre>

                                            </section>

                                            <section id="section-1-2">
                                                <h3>1.2. What's Included</h3>

                                                <p>After purchasing Abstrak template on themeforest.net with your Envato
                                                    account, go to your
                                                    Download page. You can choose to download Abstrak template only or
                                                    the entire
                                                    Abstrak template package which contains the following files:</p>

                                                <img src="assets/images/doc/unzip-file.jpg" alt="Abstrak"/>
                                                    <ul>
                                                        <li><strong>abstrak:</strong> An Installable react template
                                                            file.
                                                        </li>
                                                        <li><strong>documentation:</strong> This folder contains what
                                                            you are reading now.
                                                        </li>
                                                        <li><strong>Licensing.</strong></li>
                                                    </ul>


                                            </section>


                                            <section id="section-2">
                                                <h3>2. React Installation</h3>
                                                <p>Please follow the instructions given below how you can install react
                                                    on your local host:</p>
                                                <h6 className="m-b-10">Open you command prompt and run those command for
                                                    local host</h6>
                                                <pre>
								<code>
									npm install
									npm run start
								</code>
							</pre>
                                                <p>Know more about React Project Deployment Please <a
                                                    href="https://create-react-app.dev/docs/deployment/">Click here</a>
                                                </p>

                                            </section>

                                            <section id="section-3-1">
                                                <h3>3.1. Change Site Title and Favicon</h3>
                                                <p>To change your Site title and Favicon open the abstrak in your editor
                                                    and go to the location by following screenshot which are given
                                                    bellow.</p>
                                                <img src="assets/images/doc/site_title.jpg" alt="doc"/>
                                                    <p className="text-center">You can change Site title following this
                                                        screenshot</p>
                                                    <img src="assets/images/doc/favicon.jpg" alt="doc"/>
                                                        <p className="text-center">You can change Favicon following this
                                                            screenshot</p>
                                            </section>
                                            <section id="section-3-2">
                                                <h3>3.2. Change Logo</h3>
                                                <p>To change your Site Logo open the abstrak in your editor and go to
                                                    the location by following screenshot which are given bellow.</p>
                                                <img src="assets/images/doc/logo.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-3-3">
                                                <h3>3.3. Customize Menu</h3>
                                                <p>To customize your Site Menu open the abstrak in your editor and go to
                                                    the location by following screenshot which are given bellow.</p>
                                                <img src="assets/images/doc/menu.jpg" alt="doc"/>
                                            </section>

                                            <section id="section-3-4">
                                                <h3>3.4. Default Home Setup</h3>
                                                <p>How to set default home page</p>
                                                <img src="assets/images/doc/default_home.jpg" alt="doc"/>
                                            </section>

                                            <section id="section-4-1">
                                                <h3>4.1. Header</h3>
                                                <p>To change header settings</p>
                                                <img src="assets/images/doc/header.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-2">
                                                <h3>4.2. Footer</h3>
                                                <p>To change footer settings</p>
                                                <img src="assets/images/doc/footer.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-3">
                                                <h3>4.3. Social Icon</h3>
                                                <p>To change Social Icon</p>
                                                <img src="assets/images/doc/social_icon.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-4">
                                                <h3>4.4. Page Banner</h3>
                                                <p>To change Page Banner</p>
                                                <img src="assets/images/doc/page_banner.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-5">
                                                <h3>4.5. Coming Soon</h3>
                                                <p>To change Coming soon page date</p>
                                                <img src="assets/images/doc/coming_soon.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-6">
                                                <h3>4.6. Global Color</h3>
                                                <p>To change Abstrak Global Color</p>
                                                <img src="assets/images/doc/variable_color.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-4-7">
                                                <h3>4.6. RTL Version</h3>
                                                <p>To enable RTL Mode please follow this insturction: </p>
                                                <ul>
                                                    <li>Remove this <strong>import
                                                        'bootstrap/dist/css/bootstrap.min.css';</strong> line which is
                                                        mark with red line
                                                    </li>
                                                    <li>Add here this line: <strong>import
                                                        'bootstrap/dist/css/bootstrap.rtl.min.css';</strong> and save
                                                        the file
                                                    </li>
                                                    <li>File Location <strong>src/App.js</strong></li>
                                                </ul>
                                                <img src="assets/images/doc/rtl_bootstrap.jpg" alt="doc"/>
                                                    <ul>
                                                        <li>Uncomment this <strong>@import "rtl/rtl";</strong> line
                                                            which is mark with red line and save the file
                                                        </li>
                                                        <li>File Location <strong>src/assets/scss/app.scss</strong></li>
                                                    </ul>
                                                    <img src="assets/images/doc/rtl_css.jpg" alt="doc"/>
                                            </section>
                                            <section id="section-5">
                                                <h3>5. Support</h3>
                                                <h4>Support Scope</h4>
                                                <p><strong>Included in Free Support Scope:</strong></p>
                                                <ul>
                                                    <li>Fixing bugs</li>
                                                    <li>Helping clients in changing site contents by sending
                                                        instructions that couldn’t be
                                                        covered by documentation
                                                    </li>
                                                </ul>
                                                <p><strong><strong style={{color:"red"}}>NOT</strong> Included in Free
                                                    Support Scope:</strong>
                                                </p>
                                                <ul>
                                                    <li>Any type of custom changes</li>
                                                    <li>Any type of request to update clients’ site contents</li>
                                                </ul>
                                                <p><strong>Paid Support Scope:</strong></p>
                                                <p>Paid support is not a part of Free Support when you purchase the
                                                    template from themeforest.
                                                    We understand that some clients need custom changes while using our
                                                    template. For that we
                                                    kept an option for paid support which includes:</p>
                                                <ul>
                                                    <li>Custom change request</li>
                                                    <li>Custom pages</li>
                                                    <li>Custom features</li>
                                                </ul>
                                                <p>We kept a flat rate for our paid support which is $35/hour.</p>

                                                <h4>Support Ticket</h4>
                                                <p>Please send your <a target="_blank"
                                                                       href="http://support.axilthemes.com/support/">support
                                                    request here</a>. You will response within 1
                                                    business day.</p>
                                            </section>
                                            <section>
                                                <p style={{color:"green"}}>Regards,
                                                    \nAxilthemes Support Team</p>
                                            </section>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SplashFooter />
            </main>
        </>
    )
}

export default VotingServiceDocuments;
