import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom"
import {FaCross, FaFileWord} from "react-icons/fa";

const AttachmentListGenerate = ({
                                    attachmentObject
    , onDeleteHandler
    , isEdit = false
}) => {

    const removeFile = (fileName) => {
        console.log("Removing")
        console.log(fileName);
        if(onDeleteHandler){
            onDeleteHandler(fileName);
        }
    }

    return (
        <div className="container">
            {
                attachmentObject.map((fileName) =>
                    <li style={{listStyleType: "none"}}>
                        {isEdit &&
                            <button style={{margin: "2px 10px 2px 0px"}} type="button" onClick={(e)=>removeFile(e.target.value)} value={fileName} className="btn btn-danger btn-sm">Remove</button>
                        }
                        <a href={fileName} target="_blank"><FaFileWord/> {fileName}</a>
                    </li>
                )
            }
        </div>
    )
}




{/*<li><Link><FaFilePdf/> survey-file.pdf</Link></li>*/}
{/*<li><Link><FaFileAlt/> survey-file.hwp</Link></li>*/}

export default AttachmentListGenerate;
