import React from 'react';
import SEO from '../../common/SEO';
import HeaderLogin from "../../common/header/HeaderLogin";
import LoginBody from "../../component/about/LoginBody";
import LoginFooter from "../../common/footer/LoginFooter";


const Login = () => {

    return (
        <>
        <SEO title="KONA CHAIN | Login"/>
        <main className="main-wrapper">
            <HeaderLogin />
            <LoginBody />
        <LoginFooter/>
        </main>
        </>
    )
}

export default Login;

