import React, {useEffect, useState} from 'react';
import {CommonUtil} from "../../helpers";
import {blogApi} from "../../api/blogApi";
import {useNavigate} from "react-router-dom";

export const BlogDataDefault = {
    note: "",
    postId: "",
    status: "",
}

const ReviewBlogConfirmation = ({id}) => {
    const navigate = useNavigate()
    const [BlogData, setBlogData] = useState(BlogDataDefault);
    useEffect(() => {
        setBlogData(prevState => {
            return {
                ...prevState
                , postId: id
            }
        });
    }, [id])

    const handleChange = (name, value) => {
        setBlogData(prevState => {
            return {
                ...prevState
                , [name]: value
            }
        });
    }


    const handleSubmit = (status) => {
        if (status === "REJECT" && (!BlogData.note || BlogData.note.length < 0)) {
            window.alert("Please enter review note.")
            return;
        }
        BlogData.status = status;

        let text = "Are you sure you want to " + status;
        if (window.confirm(text) === false) {
            return;
        }

        let payload = {
            id: BlogData.postId,
            note: BlogData.note,
            status: BlogData.status
        }

        blogApi.updateBlog(payload)
            .then(res => {
                window.alert(BlogData.status + " successfully");
                navigate("/review-blog");
            })
            .catch(err => CommonUtil.handleErrorResponse(err));
    }


    return (
        <>
            <div>

                <h6>Confirmation: </h6>

                <div className="mb-3">
                    <label htmlFor="note" className="form-label">Review Note:</label>
                    <input type="text" className="form-control"
                           onChange={data => handleChange("note", data.target.value)}/>
                </div>

                <div className="mb-3" style={{textAlign: "right"}}>
                    <button type="button" className="btn btn-success" value="APPROVED"
                            onClick={event => handleSubmit(event.target.value)}>APPROVED
                    </button>

                    <button style={{marginLeft: "10px"}} type="button" className="btn btn-danger" value="REJECT"
                            onClick={event => handleSubmit(event.target.value)}>REJECT
                    </button>
                </div>

            </div>
        </>
    )
}

export default ReviewBlogConfirmation;
