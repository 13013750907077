import React from 'react';
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import {storageUtil} from "../helpers";


const HomePage = () => {


    return (
        <>
            <SEO title="KONA CHAIN | 전자 투표"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>

                <div className="section-padding-equal">
                    <div className="container">
                        <h5>
                        {storageUtil.isUserLogin() &&
                            <>{storageUtil.getUserName()} welcome to </>
                        } KONA CHAIN homepage administration page. </h5>

                        <div className="kc-center-div-p-90" style={{backgroundColor: "#1063A5"}}>
                            <img width="60%"
                                 src="/images/banner/default_image.gif"
                            />
                        </div>
                    </div>

                </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default HomePage;
