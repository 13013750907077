import React, {useEffect} from 'react';
import SEO from '../../common/SEO';
import {storageUtil} from "../../helpers";

const Logout = () => {

    useEffect(() => {
        let text = "Do you want to Logout?";
        if (window.confirm(text) === false) {
            return;
        } else {
            storageUtil.saveAuthToken(null, null, null, null, null);
            window.location.assign("/");
        }

    }, [])


    return (
        <>
        <SEO title="KONA CHAIN"/>

        </>
    )
}

export default Logout;

