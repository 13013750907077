import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaDribbble } from "react-icons/fa";


const LoginFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom" style={{borderTop: "0px"}}>
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://konachain.co.kr/">KONA CHAIN Co. Ltd.</a></span>
                        </div>
                        </div>
                        <div className="col-lg-2">

                        </div>
                        <div className="col-lg-5">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>개인정보처리방침</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default LoginFooter;
