import React from 'react';
const category =
    {
        1: 'News',
        2: 'Trend Report',
        3: 'Article',
        4: 'Research Tips'
    }
const PostCategory = ({categoryId}) => {
    return (
        <>
            {categoryId === 1 &&
                <span className="text-success">{category[categoryId]}</span>
            }
            {categoryId === 2 &&
                <span className="text-primary">{category[categoryId]}</span>
            }
            {categoryId === 3 &&
                <span className="text-warning">{category[categoryId]}</span>
            }
            {categoryId === 4 &&
                <span className="text-info">{category[categoryId]}</span>
            }
        </>
    )
}
export default PostCategory;
