import React from 'react';
import {Link} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import CustomCss from '../../assets/css/kc-custome.css'

const FooterOne = ({parentClass}) => {

    return (
        <footer className={`footer-area bg-color-dark`}>
            <div className="container">

                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-10 col-lg-5">
                            <div className="footer-widget" style={{marginBottom: "10px"}}>
                                <div className="footer-newsletter">
                                    <div className="header-logo">
                                        <img className="sticky-logo" src={process.env.PUBLIC_URL + "/images/logo_white.png"} alt="logo" />
                                    </div>
                                    <p className="kc-address-color">코나체인 주식화사<br/>
                                        KONA CHAIN CO., LTD.(약호 KONA CHAIN)<br/>
                                        서울시 영등포구 은행로 25, 5층(여의도동, 안원빌딩)
                                    </p>
                                    <p className="kc-address-color">
                                        사업자 번호 : <span className="kc-address-number">811-88-02347</span>  |
                                        대표번호 : <a className="kc-address-number" href="tel:1688-3610">1688-3610</a>  |
                                        이메일 : <a className="kc-address-number" href="mailto:admin@konachain.co.kr">admin@konachain.co.kr</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <div className="footer-menu-link">
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="col-sm-6">*/}
                                {/*    <div className="footer-widget" style={{marginTop: "70px", marginBottom : "0px"}}>*/}
                                {/*        <div className="footer-menu-link">*/}
                                {/*            <a target="_blank" href="https://facebook.com/konai.story/" className="kc-footer-social-icon"><img className="sticky-logo" src={process.env.PUBLIC_URL + "/images/icon/facebook.png"} alt="KONA CHAIN Facebook" /></a>*/}
                                {/*            <a target="_blank" href="https://blog.naver.com/konagroup" className="kc-footer-social-icon"><img className="sticky-logo" src={process.env.PUBLIC_URL + "/images/icon/naver.png"} alt="KONA CHAIN Naver" /></a>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text kc-font-color-white">© {new Date().getFullYear()}. All rights reserved by <a href="https://konachain.co.kr/" className="kc-font-color-white">KONA CHAIN Co. Ltd.</a></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link" style={{color: "white"}}>
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"} className="kc-font-color-white">개인정보처리방침 +</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"} className="kc-font-color-white">이용약관</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/voting-service-documents"} className="kc-font-color-white">고객센터</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop smooth color="#6828ff" />
        </footer>
    )
}

export default FooterOne;
