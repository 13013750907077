import {api} from "./";
import {paramUtil, storageUtil} from "../helpers";

export const tagApi = {
    getTagList
}

function getTagList(requestPayload) {
    return api.get('/api/tag/get-list', paramUtil.stringifyRequestPayload(requestPayload))
}
