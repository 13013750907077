import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import {storageUtil} from "../../helpers";


const Nav = () => {

    const logoutAction = () => {
        let text = "Do you want to Logout?";
        if (window.confirm(text) === false) {
            return;
        } else {
            storageUtil.saveAuthToken(null,null, null, null, null, null);
            window.location.assign("/");
        }

    };

    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                {/*<li className="menu-item-has-children">*/}
                {/*    <Link to="#">서비스 <FaAngleDown /> </Link>*/}
                {/*    <ul className="axil-submenu">*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/region-based-research"}>지역기반의 리서치</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/marketing-research"}>마케팅 리서치</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/electronic-voting"}>전자 투표</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/time-to-throw-away"}>버릴시간</Link></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                {/*<li className="menu-item-has-children">*/}
                {/*    <Link to="#">프로덕트 <FaAngleDown /></Link>*/}
                {/*    <ul className="axil-submenu">*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/survey-platform"}>설문조사 플랫폼</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/voting-platform"}>투표 플랫폼</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/result-analysis-platform"}>결과 분석</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/time-to-throw-away-platform"}>버릴시간</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/blockchain-baas-service"}>블록체인 BaaS 서비스</Link></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                <li className="menu-item-has-children">
                    <Link to="#">My 블로그 <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/kona-chain-news"}>코나체인 소식</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/trend-report"}>트랜드 리포트</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/research-tips"}>러서치 팁</Link></li>
                        {/*<li><Link to={process.env.PUBLIC_URL + "/kona-chain-article"}>공지사항</Link></li>*/}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">블로그 <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/post-blog"}>Post Blog</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/review-blog"}>Review Blog</Link></li>
                    </ul>
                </li>

                {/*<li><Link to={process.env.PUBLIC_URL + "/pricing-table"}>서비스/가격</Link></li>*/}
                <li><Link to={process.env.PUBLIC_URL + "/inquiry"}>서비스 문의</Link></li>
                {storageUtil.isUserLogin() &&
                    <>
                        <li><Link onClick={logoutAction}>Logout</Link></li> |
                        <li> {storageUtil.getUserName()}</li>
                    </>
                }
                {!storageUtil.isUserLogin() &&
                <li><Link to={process.env.PUBLIC_URL + "/login"}>Login</Link></li>
                }

                {/*<li className="menu-item-has-children">*/}
                {/*    <Link to="#">고객센터 <FaAngleDown /></Link>*/}
                {/*    <ul className="axil-submenu">*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/voting-service-documents"}>설문조사&투표 서비스</Link></li>*/}
                {/*        <li><Link to={process.env.PUBLIC_URL + "/contact"}>버릴시간 서비스</Link></li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
            </ul>
        </nav>
    )
}

export default Nav;
