import {Constants, paramUtil} from "../helpers";

export const storageUtil = {
    saveSearchParams
    , loadSearchParams
    , getAuthToken
    , getAuthRefreshToken
    , getAuthTokenExpire
    , getRefreshTokenExpire
    , saveAuthToken
    , getUserInfo
    , isUserLogin
    , getUserName
}

function saveSearchParams(search_params) {
    let key = window.history.state ? window.history.state.key : window.location.pathname
    sessionStorage.setItem(key, JSON.stringify(search_params))
}

function loadSearchParams() {
    let key = window.history.state ? window.history.state.key : window.location.pathname
    let searchParams = key ? sessionStorage.getItem(key) : null
    return searchParams ? JSON.parse(searchParams) : ''
}

function saveAuthToken(userName, token, refreshToken, tokenExpire, refreshTokenExpire, fullResponse) {
    sessionStorage.setItem(Constants.APP_SESSION_KEY.USER_NAME, userName);
    sessionStorage.setItem(Constants.APP_SESSION_KEY.TOKEN, token);
    sessionStorage.setItem(Constants.APP_SESSION_KEY.REFRESH_TOKEN, refreshToken);
    sessionStorage.setItem(Constants.APP_SESSION_KEY.TOKEN_EXPIRE, tokenExpire);
    sessionStorage.setItem(Constants.APP_SESSION_KEY.REFRESH_TOKEN_EXPIRE, refreshTokenExpire);
    sessionStorage.setItem(Constants.APP_SESSION_KEY.LOGIN_FULL_RESPONSE, JSON.stringify(fullResponse));
}

function getAuthToken() {
    return sessionStorage.getItem(Constants.APP_SESSION_KEY.TOKEN);
}

function getAuthRefreshToken() {
    return sessionStorage.getItem(Constants.APP_SESSION_KEY.REFRESH_TOKEN);
}

function getAuthTokenExpire() {
    return sessionStorage.getItem(Constants.APP_SESSION_KEY.TOKEN_EXPIRE);
}

function getRefreshTokenExpire() {
    return sessionStorage.getItem(Constants.APP_SESSION_KEY.REFRESH_TOKEN_EXPIRE);
}

function getUserInfo() {
    const userInfo = sessionStorage.getItem(Constants.APP_SESSION_KEY.LOGIN_FULL_RESPONSE);
    return userInfo ? JSON.parse(userInfo) : null
}

function getUserName() {
    const userInfo = sessionStorage.getItem(Constants.APP_SESSION_KEY.USER_NAME);
    return userInfo;
}

function isUserLogin() {
    const userInfo = getUserInfo();
    console.log("userInfo", userInfo)
    return !paramUtil.isEmptyObject(userInfo)
}
