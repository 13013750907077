import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {CKEditor} from 'ckeditor4-react';

const wordcount = {
    showParagraphs: false,
    showWordCount: false,
    showCharCount: false,
    countSpacesAsChars: true,
    countBytesAsChars: false,
    countHTML: true,
    countLineBreaks: true,
    hardLimit: false,
    pasteWarningDuration: 3000,
    maxCharCount: -1
}


const RichTextEditor = ({
                            data
                            , isEdit = false
                            , onChangeEventHandler
                            , placeholder
                            , maxCharCount
                        }) => {

    const getConfig = () => {
        if (maxCharCount) return {...wordcount, maxCharCount: maxCharCount, showCharCount: true}
        else return {...wordcount}
    }

    const [initData, setInitData] = useState(data)
    useEffect(() => {
        setInitData(data)
    },[data])

    const renderCKEditor = () => {
        return <CKEditor
            initData={initData}
            onFileUploadResponse={response => {
                response.removeListener();
                new Promise((resolve, reject) => {
                    setTimeout(resolve, 300);
                }).then((e) => {
                    onChangeEventHandler && onChangeEventHandler(response.editor.getData());
                });

            }}
            onChange={event => {
                if (maxCharCount && event.editor.wordCount.charCount + 1 >= maxCharCount) {
                    return 0;
                }
                const data = event.editor.getData();
                onChangeEventHandler && onChangeEventHandler(data);
            }}
            config={{
                addRules: {
                    breakAfterClose: false,
                },
                extraAllowedContent: "span",
                extraPlugins: 'uploadimage',
                filebrowserUploadUrl: `/api/attachment/ck-upload`,
                uploadUrl: `/api/attachment/ck-upload`,
                wordcount: getConfig(),
                editorplaceholder: placeholder || "",
                toolbar: [
                    {
                        name: 'row1',
                        items: ['Source', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo', 'SelectAll', 'RemoveFormat']
                    },
                    '/',
                    {
                        name: 'row2',
                        items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight',
                            'JustifyBlock', '-', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'CreateDiv', '-', 'Table', 'HorizontalRule', 'PageBreak', 'Image', 'SpecialChar']
                    }, //'Image','SpecialChar',
                    '/',
                    {
                        name: 'row3',
                        items: ['Styles', 'Format', 'Font', 'FontSize', '-', 'TextColor', 'BGColor', 'Maximize', 'ShowBlocks', 'Link', 'Unlink']
                    }, //'-', 'Link', 'Unlink'
                ]
            }}

        />
    }

    return (

        <>
            {isEdit && initData &&
                renderCKEditor()
            }
            {!isEdit &&
                renderCKEditor()
            }
        </>
    )
}

RichTextEditor.propType = {
    data:PropTypes.string,
    onChangeEventHandler:PropTypes.func,
    placeholder: PropTypes.string,
    maxCharCount:PropTypes.number
};

export default RichTextEditor;

