import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
// Home Pages Import
import DigitalAgency from './pages/DigitalAgency';
import CreativeAgency from './pages/CreativeAgency';
import PersonalPortfolio from './pages/PersonalPortfolio';
import HomeStartup from './pages/HomeStartup';
import CorporateAgency from './pages/CorporateAgency';
// Blog Import
import BlogGridView from './pages/BlogGrid';
import BlogCategory from './pages/Category';
import BlogArchive from './pages/Archive';
import BlogDetailsT from './pages/BlogDetailsT';
// Service
import ServiceOne from './pages/ServiceOne';
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';
// Project
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectGridTwo from './pages/ProjectGridTwo';
import ProjectGridThree from './pages/ProjectGridThree';
import ProjectGridFive from './pages/ProjectGridFive';
import ProjectGridFour from './pages/ProjectGridFour';
import ProjectDetails from './pages/ProjectDetails';
// Pages
import AboutUs from './pages/AboutUs';
import OurOffice from './pages/OurOffice';
import OurClients from './pages/OurClients';
import Team from './pages/Team';
import TeamDetails from './pages/TeamDetails';
import CaseStudy from './pages/CaseStudy';
import CaseDetails from './pages/CaseStudyDetails';
import Testimonials from './pages/Testimonials';
import PricingTable from './pages/PricingTable';
import Typography from './pages/Typography';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
// Css Import
import './assets/scss/app.scss';
import RegionBasedResearch from "./pages/service/RegionBasedResearch";
import MarketingResearch from "./pages/service/MarketingResearch";
import ElectronicVoting from "./pages/service/ElectronicVoting";
import TimeToThrowAway from "./pages/service/TimeToThrowAway";
import SurveyPlatform from "./pages/product/SurveyPlatform";
import VotingPlatform from "./pages/product/VotingPlatform";
import ResultAnalysisPlatform from "./pages/product/ResultAnalysisPlatform";
import TimeToThrowAwayPlatform from "./pages/product/TimeToThrowAwayPlatform";
import BlockchainBaaSService from "./pages/product/BlockchainBaaSService";
import ReviewBlog from "./pages/blog/ReviewBlog";
import PostBlog from "./pages/blog/PostBlog";
import KonaChainArticle from "./pages/blog/KonaChainArticle";
import TrendReport from "./pages/blog/TrendReport";
import VotingServiceDocuments from "./pages/documents/VotingServiceDocuments";
import CustomerContact from "./pages/contact/CustomerContact";
import Login from "./pages/login/Login";
import CustomerContactDetails from "./pages/contact/CustomerContactDetails";
import ProtectedRoute from "./ProtectedRoute";
import ReviewBlogDetails from "./pages/blog/ReviewBlogDetails";
import ResearchTips from "./pages/blog/ResearchTips";
import BlogDetails from "./pages/blog/BlogDetails";
import KonaChainNews from "./pages/blog/KonaChainNews";
import EditPostBlog from "./pages/blog/EditPostBlog";
import KonaChainNewsDetails from "./pages/blog/KonaChainNewsDetails";
import HomePage from "./pages/HomePage";
import Logout from "./pages/login/Logout";

const App = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route path={"/"} element={<HomePage/>}/>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/logout"} element={<Logout/>}/>
                    <Route path={"/*"} element={<ErrorPage/>}/>
                    {/*KONA SERVICE DOCUMENTATION */}


                    <Route element={<ProtectedRoute/>}>
                        {/* KONA CHAIN SERVICE */}
                        <Route path={"/region-based-research"}
                               element={<RegionBasedResearch/>}/>
                        <Route path={"/marketing-research"} element={<MarketingResearch/>}/>
                        <Route path={"/electronic-voting"} element={<ElectronicVoting/>}/>
                        <Route path={"/time-to-throw-away"} element={<TimeToThrowAway/>}/>

                        {/* KONA CHAIN PRODUCT */}
                        <Route path={"/survey-platform"} element={<SurveyPlatform/>}/>
                        <Route path={"/voting-platform"} element={<VotingPlatform/>}/>
                        <Route path={"/result-analysis-platform"}
                               element={<ResultAnalysisPlatform/>}/>
                        <Route path={"/time-to-throw-away-platform"}
                               element={<TimeToThrowAwayPlatform/>}/>
                        <Route path={"/blockchain-baas-service"}
                               element={<BlockchainBaaSService/>}/>


                        <Route path={"/digital-agency"} element={<DigitalAgency/>}/>
                        <Route path={"/creative-agency"} element={<CreativeAgency/>}/>
                        <Route path={"/personal-portfolio"}
                               element={<PersonalPortfolio/>}/>
                        <Route path={"/home-startup"} element={<HomeStartup/>}/>
                        <Route path={"/corporate-agency"} element={<CorporateAgency/>}/>

                        {/* KONA CHAIN Blogs */}
                        <Route path={"/kona-chain-news"} element={<KonaChainNews/>}/>
                        <Route path={"/trend-report"} element={<TrendReport/>}/>
                        <Route path={"/kona-chain-article"} element={<KonaChainArticle/>}/>
                        <Route path={"/research-tips"} element={<ResearchTips />}/>

                        <Route path={"/post-blog"} element={<PostBlog/>}/>

                        <Route path={"/inquiry"} element={<CustomerContact/>}/>
                        <Route path={"/inquiry-details/:id"} element={<CustomerContactDetails/>}/>
                        <Route path={"/post-blog"} element={<PostBlog/>}/>
                        <Route path={"/review-blog"} element={<ReviewBlog/>}/>
                        <Route path={"/review-blog-details/:id"} element={<ReviewBlogDetails/>}/>
                        <Route path={"/blog-details/:blogId"} element={<BlogDetails />}/>
                        <Route path={"/kona-chain-news-details/:id"} element={<KonaChainNewsDetails />}/>
                        <Route path={"/edit-post-blog/:blogId"} element={<EditPostBlog />}/>

                        {/*KONA SERVICE DOCUMENTATION */}
                        <Route path={"/voting-service-documents"}
                               element={<VotingServiceDocuments/>}/>




                        {/* Blogs */}
                        <Route path={"/blog-grid/"} element={<BlogGridView/>}/>
                        <Route path={"/category/:slug"} element={<BlogCategory/>}/>
                        <Route path={"/archive/:slug"} element={<BlogArchive/>}/>
                        {/*<Route path={"/blog-details/:id"} element={<BlogDetailsT/>}/>*/}

                        {/* Service */}
                        <Route path={"/service-one/"} element={<ServiceOne/>}/>
                        <Route path={"/service-two/"} element={<ServiceTwo/>}/>
                        <Route path={"/service-details/:slug"}
                               element={<ServiceDetails/>}/>

                        {/* Project  */}
                        <Route path={"/project-grid-one"} element={<ProjectGridOne/>}/>
                        <Route path={"/project-grid-two"} element={<ProjectGridTwo/>}/>
                        <Route path={"/project-grid-three"} element={<ProjectGridThree/>}/>
                        <Route path={"/project-width-one"} element={<ProjectGridFour/>}/>
                        <Route path={"/project-width-two"} element={<ProjectGridFive/>}/>
                        <Route path={"/project-details/:slug"}
                               element={<ProjectDetails/>}/>

                        {/* Pages  */}
                        <Route path={"/about-us"} element={<AboutUs/>}/>
                        <Route path={"/our-office"} element={<OurOffice/>}/>
                        <Route path={"/our-clients"} element={<OurClients/>}/>
                        <Route path={"/team"} element={<Team/>}/>
                        <Route path={"/team-details/:slug"} element={<TeamDetails/>}/>
                        <Route path={"/case-study"} element={<CaseStudy/>}/>
                        <Route path={"/case-details/:slug"} element={<CaseDetails/>}/>
                        <Route path={"/testimonials"} element={<Testimonials/>}/>
                        <Route path={"/pricing-table"} element={<PricingTable/>}/>
                        <Route path={"/typography"} element={<Typography/>}/>
                        <Route path={"/contact"} element={<Contact/>}/>
                        <Route path={"/coming-soon"} element={<ComingSoon/>}/>
                        <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                        <Route path={"/terms-use"} element={<TermsOfUse/>}/>

                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default App;
