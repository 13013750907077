/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 26/10/2022
 */

import React, {useEffect, useMemo} from 'react';
import axios from "axios";
import {CommonUtil, storageUtil} from "../helpers";
import {api} from "../api";

const AxiosInterceptorConfigurer = ({
                                        children
                                    }) => {

    useMemo(() => {
        api.interceptors.request.use(config => {
            const authToken = storageUtil.getAuthToken();

            if (authToken) {
                config.headers['Authorization'] = authToken;
                config.headers['Content-Type'] = "application/json";
            }
            return config;
        });

        api.interceptors.response.use(
            res => {
                //         // Set server timezone from response headers
                //         CommonUtil.getOrDefineServerTimezone(res.headers)
                //         if (!loaderExceptionConfig.shouldIgnoreLoader(res.config.url, res.config.method)) {
                //             decreaseLoaderStep();
                //         }
                //         // If is a error response propagate the error via context
                //         if (res.data.reason && res.data.message) {
                //             showError({
                //                 reasonCode: res.data.reason
                //                 , message: res.data.message
                //             });
                //             return Promise.reject(res.data);
                //         }
                //         return res;
            },
            err => {
                // hideLoaderStep();
                return attemptToRefreshTokenIfExpires(err);
            }
        );
    }, []);

    const attemptToRefreshTokenIfExpires = (err) => {
        console.log("attemptToRefreshTokenIfExpires : {}, status: {}", err, err.response);

        const originalRequest = err.config;
        // Prevent infinite loops
        // if (err.response.status === 401 && originalRequest.url === baseURL + '/api/auth/get-access-token') {
        //     console.log("Prevent infinite loops {}", originalRequest.url);
        //     restartApp();
        //     return Promise.reject(err);
        // }
        console.log("Error response : {}", err.response);
        if (err.response.data.status === 401 &&
            err.response.data.error === "Unauthorized" &&
            err.response.data.message === "TOKEN_EXPIRED" &&
            err.response.status === 401
        ) {
            console.log("Get refresh token");
            const refreshAuthToken = storageUtil.getAuthRefreshToken();

            if (refreshAuthToken) {
                console.log("Get token by refresh token [start]");
                return api.post('/api/auth/get-access-token', {refreshToken: refreshAuthToken})
                    .then((response) => {
                        console.log("Get access token res : {}", response);

                        // updateUserSession({
                        //     accessToken: response.data.access_token
                        //     , refreshToken: response.data.refresh_token
                        //     , refreshTokenExpires: response.data.ref_expires_in
                        // })

                        storageUtil.saveAuthToken(
                            response.data.access_token
                            , response.data.refresh_token
                            , response.data.expires_in
                            , response.data.ref_expires_in
                            , response.data
                        );
                        axios.defaults.headers['Authorization'] = response.data.access_token;
                        originalRequest.headers['Authorization'] = response.data.access_token;
                        console.log("Calling original request");
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        console.log("Get access token err : {}", err);
                        // restartApp();
                    });
            } else {
                console.log("Refresh token not available.")
                // restartApp();
            }
        } else {
            console.log("show error message")
            // showError({
            //     message: err.response.data
            //     , statusCode: err.response.status
            // });
        }
    }

    // const restartApp = () => {
    //     CommonUtil.setSession(Constants.STORE_KEY_LIST.IS_APP_DATA_LOADED, false);
    //     console.log("restartApp :: redirect to appInit");
    //     window.location.href = AppRoutes.APP_INIT.path;
    //     // window.history.replaceState(undefined, "", "/")
    // }

    return <>{children}</>;
};


AxiosInterceptorConfigurer.propTypes = {};

export default AxiosInterceptorConfigurer;
