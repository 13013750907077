import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactTags from "react-tag-autocomplete";
import {tagApi} from "../../api/tagApi";
import {Tag_Css} from "../../assets/css/kc-tage.css"

const TagAdd = ({
                    oldTags
                    ,onChangeEventHandler
}) => {

    useEffect(() => {
        handleSearch();
    }, [])

    const handleSearch = () => {
        let searchParams = {
            pageSize : 1000
            , pageNumber : 0
        }
        console.log("calling handleSearch ... ", {searchParams});
        tagApi.getTagList(searchParams)
            .then(res => {
                let s_tags = [];
                if(res.data){
                    s_tags = res.data.content;
                }
                setSuggestions(s_tags);
                }
            )
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };

    const [tags, setTags] = useState(oldTags)

    const [suggestions, setSuggestions] = useState([])

    const reactTags = useRef()

    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex))
    }, [tags])

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag])
    }, [tags])


    useEffect(() => {
        onChangeEventHandler(tags)
    }, [tags])

    useEffect(() => {
        setTags(oldTags)
    }, [oldTags])

    const showAllTags = () => {
        let tagString = "";
        suggestions.map((suggestion) =>{
            tagString += "\n"+suggestion.name;
        })
        window.alert(tagString);
    }

    const onValidate = useCallback((newTag) => {
        //return /^[a-z]{3,12}$/i.test(newTag.name)
        return true;
    })

    return (
        <>
            <ReactTags
                allowNew
                ref={reactTags}
                tags={tags}
                suggestions={suggestions}
                onDelete={onDelete}
                onAddition={onAddition}
                onValidate={onValidate}
            />
            <div style={{textAlign: "right"}}>
                <button style={{border: "0px", background: "none"}} onClick={showAllTags}><u><i>see all tags</i></u></button>
            </div>
        </>
    )
}

export default TagAdd;
