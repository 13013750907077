import React, {useEffect, useState} from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import BlogGridTwo from '../../component/blog/BlogGridTwo';
import CtaLayoutOne from '../../component/cta/CtaLayoutOne';
import BlogData from "../../data/blog/BlogData.json";
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import {slugify} from '../../utils';
import RichTextEditor from "../../component/richeditor/RichTextEditor";
import RichTextCounter from "../../component/richeditor/RichTextCounter";
import {CommonUtil, Constants} from "../../helpers";
import {blogApi} from "../../api/blogApi";
import {storageApi} from "../../api";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";
import {Link, useNavigate, useParams} from "react-router-dom";
import TextEditor from "../../component/richeditor/TextEditor";
import TagPreview from "../../component/blog/TagPreview";
import TagAdd from "../../component/blog/TagAdd";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";


const allBlogData = BlogData;

export const BlogDataDefault = {
    id: "",
    title: "",
    titleImage: "",
    description: "",
    postDate: "",
    authorId: "",
    reviewerId: null,
    reviewerNote: null,
    publishedDate: null,
    status: "",
    version: "",
    createdAt: "",
    updatedAt: "",
    authorInfo: null,
    reviewerInfo: null,
    category: {},
    tagList: [],
    hasAttachment:false,
    attachmentList: []
}

const EditPostBlog = () => {

    const navigate = useNavigate()

    const {blogId} = useParams();


    const [BlogData, setBlogData] = useState(BlogDataDefault);
    const [tags, setTags] = useState([]);
    const [attachmentList, setAttachmentList] = useState([]);
    const [descriptionLength, setDescriptionLength] = useState(0);

    useEffect(() => {
        getBlogDetails(blogId);
    }, [blogId])


    const getBlogDetails = (blogId) => {
        let searchParams = {
            postId:blogId
        }
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                    console.log("res.data => {}", res.data)
                    setBlogData(prevState => {
                            return {
                                ...prevState,
                                ...res.data
                            }
                    })
                    setTags(res.data.tagList);
                    setAttachmentListData(res.data.attachmentList)
            }

            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };

    const setAttachmentListData = (attachmentList) => {
        let currentAttachmentList = [];
        attachmentList.map((attachment) => {
            currentAttachmentList.push(attachment.fileName);
        })
        setAttachmentList(currentAttachmentList);
    }

    const onChangeTagsHandler = (tags)  => {
        console.log("onChangeTagsHandler =================")
        console.log(tags);
        setBlogData(prevState => {
            return {
                ...prevState
                , "tagList" : tags
            }
        });
    }

    const handleChange = (name, value) => {
        console.log("name => {}", name);
        console.log("value => {}", value);

        if (name === "description") {
            setDescriptionLength(value.length)
        }

        setBlogData(prevState => {
            return {
                ...prevState
                , [name]: value
            }
        });
    }

    const validation = () => {

        if(!BlogData.category){
            window.alert("Please select Category")
            return false;
        }

        // if(!BlogData.cover_photo){
        //     window.alert("Please upload a Cover photo")
        //     return false;
        // }

        if(!BlogData.title){
            window.alert("Please enter Title")
            return false;
        }

        if(!BlogData.description){
            window.alert("Please enter Description")
            return false;
        }
        // if(!BlogData.tag){
        //     window.alert("Please enter Tag")
        //     return false;
        // }

        console.log("Validation success")
        return true;
    }

    const handleSubmit = () => {
        console.log("handleSubmit .....")
        console.log(BlogData)

        if(!validation()){
            return;
        }

        let payload = {
            id: BlogData.id,
            description: BlogData.description,
            tagList: BlogData.tagList,
            title: BlogData.title,
            titleImage: BlogData.titleImage,
            // attachmentList: BlogData.attachmentList
        }

        blogApi.updatePostBlog(payload)
            .then(res => {
                window.alert("Update successfully.");
                navigate("/blog-details/" + blogId);
            })
            .catch(err => CommonUtil.handleErrorResponse(err));
    }

    //File upload section ----------------------------------------------------------------
    const uploadFile = (event) => {
        let target = event.target
        if (target.files.length > 0) {
            let file = target.files[0];
            console.log("Uploading file........")
            storageApi.uploadFile(file).then(result => {
                console.log("upload successful")
                handleFileUploadList(result.data.fileUrl);
            }).catch(err => {
                console.log("Could not upload banner image.")
                CommonUtil.handleErrorResponse(err)
            })
            console.log("upload complete")
        }
    }

    const handleFileUploadList = (fileUrl) => {
        let currentFiles = attachmentList;
        currentFiles.push(fileUrl)
        console.log("currentFiles => ");
        console.log(currentFiles);
        setAttachmentList(currentFiles);
        setBlogData(prevState => {
            return {
                ...prevState
                , "attachmentList": currentFiles
            }
        });
    }

    const onDeleteHandler = (fileName) => {
        let currentFiles = attachmentList;
        let updatedFileList = currentFiles.filter(function(e) { return e !== fileName })
        setAttachmentList(updatedFileList);
        setBlogData(prevState => {
            return {
                ...prevState
                , "attachmentList": updatedFileList
            }
        });
    }
    // <<----------------------------------------------------------------

    //Image upload section ----------------------------------------------------------------
    const uploadImage = (event) => {
        let target = event.target
        if (target.files.length > 0) {
            let file = target.files[0]
            let img = new Image()
            img.src = URL.createObjectURL(file)
            img.onload = function () {

                if (file.size > Constants.MAX_IMAGE_UPLOAD_SIZE) {
                    window.alert("Image size is over!");
                } else {
                    storageApi.uploadFile(file).then(result => {
                        handleChange("titleImage", result.data.fileUrl);
                        window.alert("Image upload success");
                    }).catch(err => {
                        console.log("Could not upload banner image.")
                        CommonUtil.handleErrorResponse(err)
                    })
                }
            }
        }
    }


    const resetImage = () => {
        handleChange("titleImage", null)
    }

    const renderImageUploadSection = () => {

        if (BlogData.titleImage == null || BlogData.titleImage.length === 0) {
            console.log("show input")
            return <td>
                <input id="inputGroupFile02" className="form-control" type="file"
                       accept={Constants.UPLOAD_PRODUCT_IMAGE_ALLOWED_TYPE}
                       onChange={(e) => uploadImage(e)}
                       placeholder="Select image"/>
            </td>

        } else {
            console.log("show image")
            return <td>
                <img style={{maxWidth: "80%"}} src={BlogData.titleImage} />
                <br/>
                <button style={{margin: "2px 10px 2px 0px"}} type="button" onClick={resetImage} className="btn btn-danger btn-sm">Remove</button>
            </td>
        }
    }
    // <<----------------------------------------------------------------



    return (
        <>
        <SEO title="KONA CHAIN | 러서치 팁" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="Blog Update"
                />
                <div className="container mb--100 mt--20">

                        <div className="mb-3">
                            <label for="blogCategory" className="form-label">Category: </label>
                            <b>
                                {BlogData.category != null && BlogData.category.name != null &&
                                    <span>{BlogData.category.name}</span>
                                }
                            </b>
                        </div>
                        {BlogData.categoryId === 1 &&
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Attachment</label>
                                {/*<input type="file" className="form-control" id="inputGroupFile02"/>*/}
                                <input id="inputGroupFile02" className="form-control" type="file"
                                       accept={Constants.UPLOAD_PRODUCT_FILE_ALLOWED_TYPE}
                                       // accept={Constants.UPLOAD_PRODUCT_IMAGE_ALLOWED_TYPE}
                                       onChange={(e) => uploadFile(e)}
                                       placeholder="Select File"/>
                                <AttachmentListGenerate
                                    isEdit
                                    attachmentObject={attachmentList}
                                    onDeleteHandler = {onDeleteHandler}
                                />
                            </div>

                        }
                        {(BlogData.categoryId === 2
                        || BlogData.categoryId === 3
                        || BlogData.categoryId === 4)  &&
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Cover Photo</label>
                                {renderImageUploadSection()}
                            </div>
                        }

                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input type="text" value={BlogData.title} className="form-control" name="title" onChange={event => handleChange("title", event.target.value)}/>
                            {/*<RichTextEditor*/}
                            {/*    isEdit={true}*/}
                            {/*    data={BlogData.title}*/}
                            {/*    onChangeEventHandler={data => handleChange("title", data)}*/}
                            {/*/>*/}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Post</label>

                            <RichTextEditor
                                isEdit
                                data={BlogData.description}
                                onChangeEventHandler={data => handleChange("description", data)}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Tags</label>
                            <br/>
                            {console.log(tags)}
                            <TagAdd
                                oldTags={tags}
                                onChangeEventHandler={onChangeTagsHandler}
                            />

                        </div>

                        <div className="mb-3">
                            <Link className="btn btn-outline-dark" to={process.env.PUBLIC_URL + `/blog-details/${blogId}`}>Back</Link>

                            <button type="button" style={{marginLeft : "10px"}} className="btn btn-primary" onClick={handleSubmit}>Update</button>
                        </div>

                    </div>
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default EditPostBlog;
