import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {FaFileDownload} from "react-icons/fa";
import {inquiryApi} from "../../api/inquiryApi";
import {CommonUtil} from "../../helpers";
import PaginationUtil from "../../helpers/PaginationUtil";
import {DateUtil} from "../../helpers/DateUtil";


const ContactHeader = () => {

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        let _pageNumber =  event.selected;
        setSearchParams(prevState => {
            return {
                ...prevState,
                pageNumber: _pageNumber
            }
        })
        setSearchOnChange(true);
        console.log(
            `User requested page number ${event.selected}`
        );
    };

    const [searchOnChange, setSearchOnChange] = useState(false);
    const [searchParams, setSearchParams] = useState({
        pageSize : 10
        , pageNumber : 0
    });

    const [listInfo, setListInfo] = useState({
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
            handleSearch();
    }, [])

    useEffect(() => {
        if(searchOnChange) {
            handleSearch();
            setSearchOnChange(false);
        }
    }, [searchOnChange])

    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        inquiryApi.getInquiryList(searchParams)
            .then(res => {
                setListInfo(prevState => {
                    return {
                        ...prevState,
                        ...res.data
                    }
                })
                }
            )
            .catch(err => CommonUtil.handleErrorResponse(err))
    };

    return (
        <>
                <div className="container mb--100">
                    <div className="section mt--20">
                        <table className="table table-bordered table-hover">
                            <thead>
                            <tr style={{backgroundColor: "var(--color-light)"}}>
                                <th scope="col" style={{width: "15%"}}>Company Name</th>
                                <th scope="col" style={{width: "25%"}}>Name</th>
                                <th scope="col" style={{width: "30%"}}>Message</th>
                                <th scope="col" style={{width: "10%"}}>Attachment</th>
                                <th scope="col" style={{width: "15%"}}>Inquiry Date</th>
                                <th scope="col" style={{width: "10%"}}>Status</th>
                                <th scope="col" style={{width: "5%"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {console.log(listInfo)}
                            {listInfo.content.map((item, itemIdx) => (
                                <tr key={itemIdx}>
                                <td>{item.companyName}</td>
                                <td>{item.name}</td>
                                <td>{item.contactMessage}</td>
                                <td align={"center"}>
                                    {item.hasAttachment &&
                                        <FaFileDownload />
                                    }
                                    {!item.hasAttachment &&
                                    "-"
                                    }
                                </td>
                                <td>{DateUtil.format(item.createTime)}</td>
                                <td>
                                    {item.status === 'ACTIVE' &&
                                    <span className="badge bg-info">{item.status}</span>
                                    }
                                    {item.status === 'DONE' &&
                                    <span className="badge bg-success">{item.status}</span>
                                    }
                                    {(item.status !== 'DONE' && item.status !== 'ACTIVE')  &&
                                    <span className="badge bg-warning text-dark">{item.status}</span>
                                    }

                                </td>
                                <td>
                                    <Link to={process.env.PUBLIC_URL + "/inquiry-details/"+ item.id}>
                                        <button type="button" className="btn btn-outline-info btn-sm">Details</button>
                                    </Link>
                                </td>
                            </tr>
                            ))}

                            </tbody>
                        </table>

                        <PaginationUtil
                            handlePageClick={handlePageClick}
                            totalPages={listInfo.totalPages}
                        />
                    </div>
                </div>


        </>
    )
}

export default ContactHeader;
