import React from 'react';
import PropTypes from "prop-types";
import {CKEditor} from 'ckeditor4-react';

const wordcount = {
    showParagraphs: false,
    showWordCount: false,
    showCharCount: false,
    countSpacesAsChars: true,
    countBytesAsChars: false,
    countHTML: true,
    countLineBreaks: true,
    hardLimit: false,
    pasteWarningDuration: 3000,
    maxCharCount: -1
}


const TextEditor = ({
                            data
                            , onChangeEventHandler
                            , placeholder
                            , maxCharCount
                        }) => {



    return (
        <>
            <textarea value={data} onChange={onChangeEventHandler}/>
            </>


    )
}

TextEditor.propType = {
    data:PropTypes.string,
    onChangeEventHandler:PropTypes.func,
    placeholder: PropTypes.string,
    maxCharCount:PropTypes.number
};

export default TextEditor;

