import React, {useEffect, useState} from 'react';
import SEO from '../../common/SEO';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import {Link, useParams} from "react-router-dom";
import {FaArrowLeft, FaArrowRight, FaEnvelope, FaFileAlt, FaFilePdf, FaFileWord, FaMobileAlt} from "react-icons/fa";
import {slugify} from "../../utils";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import ReviewBlogConfirmation from "./ReviewBlogConfirmation";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";


const ReviewBlogDetails = () => {
    const params = useParams();
    const inquiryId = parseInt(params.id);
    console.log(inquiryId);
    // console.log(konaNewData)
    // const konaNewDetailsData = konaNewData.filter(data => data.id === inquiryId);
    // const konaNewDetailsNextData = konaNewData.filter(data => data.id === (inquiryId+1));
    // const konaNewDetailsPreviousData = konaNewData.filter(data => data.id === (inquiryId-1));
    // const detailsData = konaNewDetailsData ? konaNewDetailsData[0] : undefined;
    // const nextDetailsData = konaNewDetailsNextData ? konaNewDetailsNextData[0] : undefined;
    // const previousDetailsData = konaNewDetailsPreviousData ? konaNewDetailsPreviousData[0] : undefined;


    const [searchParams, setSearchParams] = useState({
        postId:inquiryId
    });

    useEffect(() => {
        handleSearch();
    }, [inquiryId])

    const [blogDetails, setBlogDetails] = useState({
        id: "",
        title: "",
        titleImage: "",
        description: "",
        postDate: "",
        authorId: "",
        reviewerId: null,
        reviewerNote: null,
        publishedDate: null,
        status: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        authorInfo: null,
        reviewerInfo: null,
        category: null,
        tagList: [],
        hasAttachment:false,
        attachmentList: []
    });

    const handleSearch = () => {
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                    setBlogDetails(prevState => {
                        return {
                            ...prevState,
                            ...res.data
                        }
                    })
                }
            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };


    return (
        <>
            <SEO title="KONA CHAIN | Review Blog" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="Review Blog Details"
                />
                <div className="container mb--100 mt--20">
                        <div className="row align-items-center">
                            <div className="col-lg-12 mb--100">
                                <div className="team-details-content">
                                    <h5 className="title" style={{borderBottom: "2px solid var(--color-primary)", margin: "0 0 10px 0"}}
                                        dangerouslySetInnerHTML={{__html: blogDetails.title}}>
                                    </h5>

                                    <ul className="social-share list-unstyled">
                                        <li>작성일 : {DateUtil.format(blogDetails.postDate)}</li>
                                    </ul>

                                    {blogDetails.titleImage &&
                                    <img width="60%"
                                         src={`${blogDetails.titleImage}`}
                                         onError={(e) => (e.target.src = "/images/blog/default_image.png")}
                                    />
                                    }

                                    <p dangerouslySetInnerHTML={{__html: blogDetails.description}}></p>

                                    {blogDetails.hasAttachment &&
                                       <>
                                           <h6>Attachment</h6>
                                           <div className="row">
                                               <div className="col-md-12 col-sm-6 mb--10">
                                                   <ol className="list-style">
                                                       {
                                                           blogDetails.attachmentList.map((attachment) => <li>
                                                               <a href={attachment.fileUrl} target="_blank"><FaFileWord/> {attachment.fileName}</a></li>)
                                                       }
                                                       {/*<li><Link><FaFilePdf/> survey-file.pdf</Link></li>*/}
                                                       {/*<li><Link><FaFileAlt/> survey-file.hwp</Link></li>*/}
                                                   </ol>
                                               </div>
                                           </div>
                                       </>
                                    }


                                    {blogDetails.status === 'PENDING' &&
                                        <div className="mt--20">
                                            <ReviewBlogConfirmation id={blogDetails.id}/>
                                        </div>
                                    }


                                    <div className="mt--20">
                                        <Link to={process.env.PUBLIC_URL + "/review-blog"}>
                                            <button type="button" className="btn btn-dark">List</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>


                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ReviewBlogDetails;
