import React from 'react';
import {Link} from 'react-router-dom';
import {AnimationOnScroll} from "react-animation-on-scroll";


const BannerHomePageThree = ({
                               backgroundImageUrl
                               , bannerClassName
                               , topSubTitleText
                               , titleText
                               , subTitleText
                               , button1
                               , button2
                               , rightImageUrl
                               , quoteTextList
                           }
) => {
    return (
        <div className= {`banner banner-style-5 ${bannerClassName}`}
             style={{backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImageUrl})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-7">
                        <div className="banner-content">
                            <h5 className="kc-font-color-white"
                                dangerouslySetInnerHTML={{__html: topSubTitleText}}></h5>
                            <br/>
                            <h1 className="kc-title kc-font-color-white"
                                dangerouslySetInnerHTML={{__html: titleText}}></h1>
                            <h5 className="kc-font-color-white"
                                dangerouslySetInnerHTML={{__html: subTitleText}}></h5>
                            {button1 &&
                            <Link to={process.env.PUBLIC_URL + "/inquiry"}
                                  className="axil-btn btn-fill-primary btn-large kc-axil-btn kc-font-color-white"
                                  dangerouslySetInnerHTML={{__html: button1}}></Link>
                            }
                            {button2 &&
                            <Link to={process.env.PUBLIC_URL + "/#"}
                                  className="axil-btn btn-fill-primary btn-large kc-axil-btn ml--20 kc-font-color-white"
                                  dangerouslySetInnerHTML={{__html: button2}}></Link>
                            }
                            <br/>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BannerHomePageThree;
