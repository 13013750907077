import React from 'react';
import qs from 'qs';

export const CommonUtil = {
    getBrowserUrl
    , goToRoute
    , processDownloadUrl
    , handleErrorResponse
    , getRowId
}

function getBrowserUrl() {
    let devUrl = process.env.REACT_APP_API_ROOT;
    // if (devUrl.startsWith("http")) {
    //     return devUrl;
    // }
    return window.location.protocol + "//" + window.location.host;
}

function processDownloadUrl(downloadUri) { //need to modify to fetch only baseUrl
    if (!downloadUri) //need to modify to fetch only baseUrl
        return downloadUri;
    let path = downloadUri;
    if (downloadUri.startsWith("http")) {
        path = new URL(downloadUri).pathname;
    }
    return this.getBrowserUrl() + path;
}

function goToRoute(_this, routeURI) {
    _this(routeURI);
}

function handleErrorResponse(err, callback, args, type) {
    if(typeof err === "string") {
        errorToast(err);
        return;
    }
    if (!err.response) {
        if (err.message === 'Network Error') {
            let message = err.message;
            if (!message) {
                message = "Oops!! Something went wrong. Please check your internet connection or try again later";
            }
            errorToast(message);
        }
        return;
    }
    const { data } = err.response;
    //const locale = locale ? locale : 'defaults'
    // let message = translations[locale].error[data.reason];
    let message = data.reason;
    if (!message || message === 'Messages.error.' + data.reason) {
        message = data.message // If message not found in local
        if (message) {
            message = message.replace('==>', '');
        }
    }
    if (callback) {
        errorToast(message, {
            onCloseCallback: () => {
                args ? callback.apply(callback, Array.prototype.slice.call(args, 0)) : callback()
            }
        })
    } else {
            if (type === 'alert') {
                alert(message);
            } else {
                errorToast(message);
            }

        }
}

function warnToast(message, options) {
    // showToast(message, options, toast.TYPE.WARNING)
    console.log("WARNING: " + message)
}

function successToast(message, options) {
    // showToast(message, options, toast.TYPE.SUCCESS)
    console.log("SUCCESS: " + message)
}

function errorToast(message, options) {
    // showToast(message, options, toast.TYPE.ERROR)
    console.log("ERROR: " + message)
    window.alert("ERROR: " + message);
}

function infoToast(message, options) {
    // showToast(message, options, toast.TYPE.INFO)
    console.log("INFO: " + message)
}

function showToast(message, options = {}, type) {
    console.log("showToast : " + message)
    // if (_.isNil(options.autoClose)) {
    //     options.autoClose = Constants.number.AUTO_CLOSE
    // }
    // if (_.isNil(options.pauseOnHover)) {
    //     options.pauseOnHover = true;
    // }
    // if (_.isNil(options.pauseOnFocusLoss)) {
    //     options.pauseOnFocusLoss = false
    // }
    // if (!_.isNil(type)) {
    //     options.type = type
    // }
    // options.onOpen = () => toggleToastDim('onOpen', options.onOpenCallback)
    // options.onClose = () => toggleToastDim('onClose', options.onCloseCallback)
    // toast(message, options)
}


function getRowId(index, currentPageNumber, rowPerPage, totalElement = 0, order = 'ASC') {
    if (order === 'DESC') {
        // console.log({totalElement, index, rowPerPage, currentPageNumber})
        return (totalElement - (1 + index + rowPerPage * (currentPageNumber))) + 1;
    } else {
        return 1 + index + rowPerPage * (currentPageNumber);
    }
}
