import axios from "axios";
import {CommonUtil} from "../helpers";

export const storageApi = {
    uploadFile
};

function uploadFile(file) {
    const url = CommonUtil.processDownloadUrl('/api/file-upload')
    // const url = "http://localhost:8085/api/file-upload";
    let formData = new FormData()
    formData.append("file", file)
    return axios({
        method: 'post',
        url: url,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then(
        response => {
            return response;
        },
        error => {
            return Promise.reject(error);
        }
    )
}
