import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../../component/blog/BlogSidebar';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import SEO from '../../common/SEO';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import {blogApi} from "../../api/blogApi";
import {DateUtil} from "../../helpers/DateUtil";
import TagPreview from "../../component/blog/TagPreview";
import {Constants} from "../../helpers";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";


const BlogDetails = () => {

    const {blogId} = useParams();

    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    useEffect(() => {
        handleSearch(blogId);
    }, [blogId])

    const [attachmentList, setAttachmentList] = useState([]);

    const [blogDetails, setBlogDetails] = useState({
        id: "",
        title: "",
        titleImage: "/images/blog/blog-8.png",
        description: "",
        postDate: "",
        authorId: "",
        reviewerId: null,
        categoryId: null,
        reviewerNote: null,
        publishedDate: null,
        status: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        authorInfo: null,
        reviewerInfo: null,
        category: null,
        tagList: [],
        attachmentList: []
    });

    const handleSearch = (blogId) => {
        let searchParams = {
            postId:blogId
        }
        console.log("calling handleSearch ... ", {searchParams});
        blogApi.getBlogDetails(searchParams)
            .then(res => {
                    setBlogDetails(prevState => {
                            return {
                                ...prevState,
                                ...res.data
                            }
                        })
                    setAttachmentListData(res.data.attachmentList);

            }

            )
            // .catch(err => CommonUtil.handleErrorResponse(err))
            .catch(err => {
                console.log("ERROR: " + err)
            })
    };

    const setAttachmentListData = (attachmentList) => {
        let currentAttachmentList = [];
        attachmentList.map((attachment) => {
            currentAttachmentList.push(attachment.fileName);
        })
        setAttachmentList(currentAttachmentList);
    }

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,

      };

    const navigate = useNavigate()
    const handleGotoEditPage = () => {
        console.log('handleGotoEditPage')
        navigate("/edit-post-blog/" + blogId)
    }

    return (
        <>
            <SEO title="KONA CHAIN" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="My Post Details"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <h4 className="title" dangerouslySetInnerHTML={{__html: blogDetails.title}}></h4>
                        <div style={{textAlign: "right"}}>
                            <button type="button" onClick={handleGotoEditPage} className="btn btn-outline-primary">Update</button>
                        </div>

                        <div className="row row-40">

                            <div className="col-lg-8">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        {(blogDetails.categoryId === 2
                                            || blogDetails.categoryId === 3
                                            || blogDetails.categoryId === 4) &&
                                                <div className="post-thumbnail">
                                                    <img width="100%"
                                                         src={`${blogDetails.titleImage}`}
                                                         onError={(e) => (e.target.src = "/images/blog/default_image.png")}
                                                    />
                                                </div>
                                        }
                                        <div className="author">
                                            <div className="author-thumb">
                                            </div>
                                            <div className="info">
                                                <h6 className="author-name">{blogDetails.authorInfo? blogDetails.authorInfo.name : ""}</h6>
                                                <ul className="blog-meta list-unstyled">
                                                    <li>{DateUtil.format(blogDetails.postDate)}</li>
                                                </ul>
                                            </div>
                                        </div>
                                            <div dangerouslySetInnerHTML={{__html: blogDetails.description}}></div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row row-40 mb--40">
                            <h5>Attachments</h5>
                            {blogDetails.categoryId === 1 &&
                            <AttachmentListGenerate
                                attachmentObject={attachmentList}
                            />

                            }
                        </div>
                    </div>
                    <TagPreview tagList = {blogDetails.tagList} />
                </div>

            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogDetails;
