/**
 *  @author Md. Abdullah Al Shafi
 *  @email abdullah.shafi@konasl.com
 *  @date 26/10/2022
 */

import React from 'react';
import {storageUtil} from "./helpers";
import {Navigate, Outlet} from "react-router";

const ProtectedRoute = () => {
	const isLoggedIn = storageUtil.isUserLogin();

	return (
		isLoggedIn ? <Outlet/> : <Navigate to={"/login"}/>
	);
};

ProtectedRoute.propTypes = {};

export default ProtectedRoute;
