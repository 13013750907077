import React, {useState} from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderOne from '../../common/header/HeaderOne';
import SEO from '../../common/SEO';
import BlogData from "../../data/blog/BlogData.json";
import BreadCrumbOne from '../../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../../elements/switcher/ColorSwitcher';
import {slugify} from '../../utils';
import RichTextEditor from "../../component/richeditor/RichTextEditor";
import {CommonUtil, Constants} from "../../helpers";
import {blogApi} from "../../api/blogApi";
import {storageApi} from "../../api";
import AttachmentListGenerate from "../../component/blog/AttachmentListGenerate";
import TagAdd from "../../component/blog/TagAdd";
import BannerHomePageThree from "../../elements/breadcrumb/BannerHomePageThree";
import {useNavigate} from "react-router-dom";


const allBlogData = BlogData;

export const BlogDataDefault = {
    category: "",
    title: "",
    titleImage: "",
    description: "",
    tags: [],
    attachmentList: []
}

const PostBlog = () => {
    const navigate = useNavigate()
    const blogSlug = "information-technology";

    const getBlogData = allBlogData.filter(blog => slugify(blog.category) === blogSlug);

    const [BlogData, setBlogData] = useState(BlogDataDefault);
    const [tags, setTags] = useState([]);
    const [attachmentList, setAttachmentList] = useState([]);
    const [descriptionLength, setDescriptionLength] = useState(0);

    const handleChange = (name, value) => {
        console.log("name => {}", name);
        console.log("value => {}", value);

        if (name === "description") {
            setDescriptionLength(value.length)
        }
        setBlogData(prevState => {
            return {
                ...prevState
                , [name]: value
            }
        });
    }

    const onChangeTagsHandler = (tags) => {
        setBlogData(prevState => {
            return {
                ...prevState
                , "tags": tags
            }
        });
    }

    const validation = () => {

        if (!BlogData.category) {
            window.alert("Please select Category")
            return false;
        }

        // if(!BlogData.cover_photo){
        //     window.alert("Please upload a Cover photo")
        //     return false;
        // }

        if (!BlogData.title) {
            window.alert("Please enter Title")
            return false;
        }

        if (!BlogData.description) {
            window.alert("Please enter Description")
            return false;
        }

        console.log("Validation success")
        return true;
    }

    const handleSubmit = () => {
        console.log("handleSubmit .....")
        if (!validation()) {
            return;
        }

        let payload = {
            categoryId: BlogData.category,
            description: BlogData.description,
            tagList: BlogData.tags,
            title: BlogData.title,
            titleImage: BlogData.titleImage,
            attachmentList: BlogData.attachmentList
        }

        blogApi.postBlog(payload)
            .then(res => {
                window.alert("Post submitted successfully.");
                // window.reload();
                if(BlogData.category === "1"){
                    navigate("/kona-chain-news");
                } else if(BlogData.category === "2"){
                    navigate("/trend-report");
                } else if(BlogData.category === "3"){
                    navigate("/kona-chain-article");
                } else if(BlogData.category === "4"){
                    navigate("/research-tips");
                }

            })
            .catch(err => CommonUtil.handleErrorResponse(err));
    }

    //File upload section ----------------------------------------------------------------
    const uploadFile = (event) => {
        let target = event.target
        if (target.files.length > 0) {
            let file = target.files[0];
            console.log("Uploading file........")
            storageApi.uploadFile(file).then(result => {
                console.log("upload successful")
                handleFileUploadList(result.data.fileUrl);
            }).catch(err => {
                console.log("Could not upload banner image.")
                CommonUtil.handleErrorResponse(err)
            })
            console.log("upload complete")
        }
    }

    const handleFileUploadList = (fileUrl) => {
        let currentFiles = attachmentList;
        currentFiles.push(fileUrl)
        console.log("currentFiles => ");
        console.log(currentFiles);
        setAttachmentList(currentFiles);
        setBlogData(prevState => {
            return {
                ...prevState
                , "attachmentList": currentFiles
            }
        });
    }

    const onDeleteHandler = (fileName) => {
        let currentFiles = attachmentList;
        let updatedFileList = currentFiles.filter(function (e) {
            return e !== fileName
        })
        setAttachmentList(updatedFileList);
        setBlogData(prevState => {
            return {
                ...prevState
                , "attachmentList": updatedFileList
            }
        });
    }
    // <<----------------------------------------------------------------

    //Image upload section ----------------------------------------------------------------
    const uploadImage = (event) => {
        let target = event.target
        if (target.files.length > 0) {
            let file = target.files[0]
            let img = new Image()
            img.src = URL.createObjectURL(file)
            img.onload = function () {

                if (file.size > Constants.MAX_IMAGE_UPLOAD_SIZE) {
                    window.alert("Image size is over!");
                } else {
                    storageApi.uploadFile(file).then(result => {
                        handleChange("titleImage", result.data.fileUrl);
                        window.alert("Image upload success");
                    }).catch(err => {
                        console.log("Could not upload banner image.")
                        CommonUtil.handleErrorResponse(err)
                    })
                }
            }
        }
    }

    const resetImage = () => {
        handleChange("titleImage", null)
    }

    const renderImageUploadSection = () => {

        if (BlogData.titleImage == null || BlogData.titleImage.length === 0) {
            console.log("show input")
            return <td>
                <input id="inputGroupFile02" className="form-control" type="file"
                       accept={Constants.UPLOAD_PRODUCT_IMAGE_ALLOWED_TYPE}
                       onChange={(e) => uploadImage(e)}
                       placeholder="Select image"/>
            </td>

        } else {
            console.log("show image")
            return <td>
                <img style={{maxWidth: "60%"}} src={BlogData.titleImage}/>
                <br/>
                <button style={{margin: "2px 10px 2px 0px"}} type="button" onClick={resetImage}
                        className="btn btn-danger btn-sm">Remove
                </button>
            </td>
        }
    }
    // <<----------------------------------------------------------------


    return (
        <>
            <SEO title="KONA CHAIN | 러서치 팁"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerHomePageThree
                    backgroundImageUrl="/images/banner/blog-bg.jpg"
                    bannerClassName="kc-min-height--600 kc-pt--150"
                    titleText="Post Blog"
                />
                <div className="container mb--100 mt--20">
                    <div className="mb-3">
                        <label for="blogCategory" className="form-label">Category</label>
                        <select className="form-select form-select-sm mb-3" aria-label=".form-select-lg example"
                                onChange={event => handleChange("category", event.target.value)}>
                            <option selected value="">Select Category</option>
                            <option value="1">News</option>
                            <option value="2">Trend Report</option>
                            <option value="3">Article</option>
                            <option value="4">Research Tips</option>
                        </select>
                    </div>
                    {BlogData.category === '1' &&
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Attachment</label>
                        <input id="inputGroupFile02" className="form-control" type="file"
                               value={""}
                               accept={Constants.UPLOAD_PRODUCT_FILE_ALLOWED_TYPE}
                               onChange={(e) => uploadFile(e)}
                               placeholder="Select File"/>
                        <AttachmentListGenerate
                            isEdit
                            attachmentObject={attachmentList}
                            onDeleteHandler={onDeleteHandler}
                        />
                    </div>

                    }
                    {(BlogData.category === '2'
                        || BlogData.category === '3'
                        || BlogData.category === '4') &&
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Cover Photo</label>
                        {renderImageUploadSection()}
                    </div>
                    }

                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input type="text" value={BlogData.title} className="form-control" name="title" onChange={event => handleChange("title", event.target.value)}/>
                        {/*<RichTextEditor*/}
                        {/*    data={BlogData.title}*/}
                        {/*    onChangeEventHandler={data => handleChange("title", data)}*/}
                        {/*/>*/}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Blog Content</label>
                        <RichTextEditor
                            data={BlogData.description}
                            onChangeEventHandler={data => handleChange("description", data)}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Tags</label>
                        <TagAdd
                            oldTags={tags}
                            onChangeEventHandler={onChangeTagsHandler}
                        />

                    </div>


                    <div className="mb-3">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Post</button>
                    </div>

                </div>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default PostBlog;
