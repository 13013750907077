import React, {useRef, useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import {LoginApi} from "../../api";
import {storageUtil} from "../../helpers";
import {useNavigate} from "react-router-dom"

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Login has been successfully complete.
        </Alert>
    )
}

const FormLogin = () => {

    const navigate = useNavigate()
    const form = useRef();

    const [result, setResult] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const elements = e.target.elements
        const payload = {
            username: elements.username.value,
            password: elements.password.value
        }

        LoginApi.login(payload).then(resData => {
            console.log("login response resData", resData)
            const response = resData.data
            storageUtil.saveAuthToken(response.name, response.access_token, response.refresh_token, response.expires_in,
                response.ref_expires_in, response)
            form.current.reset();
            setResult(true);
            navigate("/")
        }).catch(err => {
            console.log("login Error", err)
            window.alert("User name or password incorrect.");
        })
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);

    return (
        <form ref={form} onSubmit={(e) => onSubmit(e)} className="axil-contact-form">
            <div className="form-group">
                <label>Username</label>
                <input type="text" className="form-control" name="username" maxLength={200} placeholder="Enter username" required/>
            </div>
            <div className="form-group">
                <label>Password</label>
                <input type="password" className="form-control" name="password" maxLength={20} placeholder="Enter password" required/>
            </div>

            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary"
                        name="submit-btn">Login
                </button>
            </div>
            <div className="form-group">
                {result ? <Result/> : null}
            </div>
        </form>
    )
}

export default FormLogin;
