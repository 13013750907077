import React from 'react';
import { Link } from 'react-router-dom';
import {DateUtil} from "../../helpers/DateUtil";


const BlogGridTwo = ({data, colSize}) => {


    return (
        <>
            {data.map((blog) => (
                <div className={colSize} key={blog.id}>
                    <div className="blog-grid blog-grid-2">
                        <div className="post-thumbnail">
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${blog.id}`}>
                                <img src={`${blog.titleImage}`} alt="Blog"
                                    onError={(e) => (e.target.src = "/images/blog/default_image.png")}
                                />
                            </Link>
                        </div>
                        <div className="author">
                            <div className="info">
                                <h6 className="author-name">{blog.name}</h6>
                                <ul className="blog-meta list-unstyled">
                                    <li>{DateUtil.format(blog.postDate)}</li>
                                    {/*<li>{blog.read_time}</li>*/}
                                </ul>
                            </div>
                        </div>
                        <p>
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${blog.id}`} dangerouslySetInnerHTML={{__html: blog.title}}></Link>
                        </p>
                        {/*<p className="kc-p" dangerouslySetInnerHTML={{__html: blog.description}}></p>*/}
                        {/*<Link className="axil-btn btn-borderd" to={process.env.PUBLIC_URL + `/blog-details/${blog.id}`}>Read More</Link>*/}
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogGridTwo;
